import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    footer: null,
    width: 600
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
        id: "adjustmentCategoryForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
                id: "name",
                required: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Description" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_textarea, {
                value: _ctx.form.description,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
                "allow-clear": "",
                autosize: { minRows: 4, maxRows: 4 }
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Type" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                ref: "select",
                value: _ctx.form.type,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.type) = $event)),
                "show-search": "",
                filterOption: _ctx.filterOption
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "Positive" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Positive")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "Negative" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Negative")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": { span: 18, offset: 5 } }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    key: "back",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancel')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Cancel ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_button, {
                    key: "submit",
                    type: "primary",
                    htmlType: "submit",
                    loading: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Submit ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}