
import Form from './Form.vue'
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs, reactive, UnwrapRef } from 'vue'
import { FormState } from "@/interfaces/adjustment_category";

export default defineComponent({
  components: {
    Form
  },
  setup () {
    const form: UnwrapRef<FormState> = reactive({
      id: '',
      name: '',
      description: '',
      type: ''
    })
    const form_action = ref('save')
    const visible = ref(false)
    const store = useStore()

    const fields = ref([
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('AdjustmentCategory/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, name, description, type} = toRefs(form)
      store
          .dispatch('AdjustmentCategory/show', record.id)
          .then(
              (res) => (
                  id.value = res.data.id,
                  name.value = res.data.name,
                  description.value = res.data.description,
                  type.value = res.data.type
              )
          )
      visible.value = true
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {id, name, type, description} = toRefs(form)
      id.value = ''
      name.value = ''
      description.value = ''
      type.value = ''
      visible.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('AdjustmentCategory/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      form_action.value === 'save' ? storeRecord(record) : updateRecord(record)
    }

    const storeRecord = (payload: any) => {
      store
          .dispatch('AdjustmentCategory/store', payload)
          .then(() => (visible.value = false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('AdjustmentCategory/update', payload)
          .then(() => (visible.value = false))
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const categories = computed(() => store.getters['AdjustmentCategory/adjustment_categories'])
    const loading = computed(() => store.getters['AdjustmentCategory/status'])

    onMounted(index)

    return {
      loading,
      categories,
      fields,
      visible,
      form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    }
  },
})
